import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { TestFrameComponent } from '../pages/test-frame/test-frame.component';
import { GenerateBadgeFrameComponent } from '../frames/generate-badge-frame/generate-badge-frame.component';
import { SendMsgFrameComponent } from '../frames/send-msg-frame/send-msg-frame.component';
import { ReplyMsgFrameComponent } from '../frames/reply-msg-frame/reply-msg-frame.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { SendMsgComponent } from '../pages/send-msg/send-msg.component';
import { ReplyMsgComponent } from '../pages/reply-msg/reply-msg.component';
import { GenerateBadgeComponent } from '../pages/generate-badge/generate-badge.component';
import { DocsComponent } from '../pages/docs/docs.component';


const routes: Routes = [
  // Common S&E
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'docs', component: DocsComponent, pathMatch: 'full' },
  { path: 'generate-badge', component: GenerateBadgeComponent, pathMatch: 'full' },
  { path: 'send/:receiver', component: SendMsgComponent, pathMatch: 'full' },
  { path: 'reply/:sender/:receiver', component: ReplyMsgComponent, pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent },

  // Test Routes
  { path: 'frame', component: TestFrameComponent, pathMatch: 'full' },
  // { path: 'frame/send/:receiver', component: TestFrameComponent, pathMatch: 'full' },
  // { path: 'frame/reply/:sender/:receiver', component: TestFrameComponent, pathMatch: 'full' },


  // Frames links
  { path: 'api/generate-badge/:apiKey', component: GenerateBadgeFrameComponent, pathMatch: 'full' },
  // { path: 'api/send', component: SendMsgFrameComponent, pathMatch: 'full' },
  // { path: 'api/send/:receiver', component: SendMsgFrameComponent, pathMatch: 'full' },
  // { path: 'api/reply', component: ReplyMsgFrameComponent, pathMatch: 'full' },
  // { path: 'api/reply/:sender/:receiver', component: ReplyMsgFrameComponent, pathMatch: 'full' },

  { path: '**', component: NotFoundComponent },


  // { path: 'generate-code', component: GenerateCodeComponent, pathMatch: 'full' },
  // { path: 'exchange/:id', component: ExchangeComponent, pathMatch: 'full' },
  // { path: 'exchange/reply/:senderId/:receiverId', component: ExchangeReplyComponent, pathMatch: 'full' },

  // Specific Enterprise S&E
  // { path: 'enterprise/:name', component: HomeComponent, pathMatch: 'full' },
  // { path: 'enterprise/:name/home', component: HomeComponent, pathMatch: 'full' },
  // { path: 'enterprise/:name/generate-code', component: GenerateCodeComponent, pathMatch: 'full' },
  // { path: 'enterprise/:name/exchange/:id', component: ExchangeComponent, pathMatch: 'full' },
  // { path: 'enterprise/:name/exchange/reply/:senderId/:receiverId', component: ExchangeReplyComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
