import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { XChangeService } from 'src/app/services/x-change.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IReplyMsg } from 'src/app/interfaces/IReplyMsg';

@Component({
  selector: 'app-reply-msg-frame',
  templateUrl: './reply-msg-frame.component.html',
  styleUrls: ['./reply-msg-frame.component.scss']
})
export class ReplyMsgFrameComponent implements OnInit {

  @ViewChild('loadingModal', null) loadingModal: TemplateRef<any>;
  @ViewChild('successModal', null) successModal: TemplateRef<any>;
  @ViewChild('errorModal', null) errorModal: TemplateRef<any>;

  homeUrl = '';
  senderId: string;
  receiverId: string;
  exchangeMsgForm: FormGroup;
  defaultMessage = 'Message par défault';
  isLoading = false;
  isSubmitted = false;
  isEmailUsed = false;
  isPhoneNumberUsed = false;
  errorMessage: string;


  constructor(
    private readonly xchangeService: XChangeService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.initializeParams();
  }

  initializeForm() {
    this.exchangeMsgForm = new FormGroup({
      message: new FormControl(),
    });
  }

  initializeParams() {
    // From parent window path
    const pathDevided = window.parent.location.href.split('/');
    this.senderId = pathDevided[pathDevided.length - 2];
    this.receiverId = pathDevided[pathDevided.length - 1];
    console.log('senderId', this.senderId);
    console.log('receiverId', this.receiverId);

  }

  sendMessage() {
    const body: IReplyMsg = {
      message: this.exchangeMsgForm.getRawValue().message,
      qrCodeSender: this.senderId,
      qrCodeReciever: this.receiverId,
    };

    this.isLoading = true;
    this.openLoadingModal();
    this.xchangeService.replyMsg(body).subscribe(
      res => {
        this.isLoading = false;
        this.closeModals();
        this.openSuccessModal();
      }, error => {
        this.isLoading = false;
        this.closeModals();
        this.openErrorModal(error.status);
      }
    );
  }

  openLoadingModal() {
    this.modalService.open(this.loadingModal, {
      centered: true,
      windowClass: 'loadingModalClass',
      backdrop: 'static',
    });
  }

  openSuccessModal() {
    this.modalService.open(this.successModal, {
      centered: true,
    });
  }

  openErrorModal(errorCode: number) {
    this.modalService.open(this.errorModal, {
      centered: true,
    });
    if (errorCode === 404) {
      this.errorMessage = 'Utilisateur distinataire non reconnu.';
    }
  }

  closeModals() {
    this.modalService.dismissAll();
  }


}
