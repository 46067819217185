import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './modules/app-routing.module';
import { QRCodeModule } from 'angular2-qrcode';
import { AppComponent } from './app.component';
import { GenerateBadgeFrameComponent } from './frames/generate-badge-frame/generate-badge-frame.component';
import { SendMsgFrameComponent } from './frames/send-msg-frame/send-msg-frame.component';
import { ReplyMsgFrameComponent } from './frames/reply-msg-frame/reply-msg-frame.component';
import { HomeComponent } from './pages/home/home.component';
import { TestFrameComponent } from './pages/test-frame/test-frame.component';
import { QrBadgeComponent } from './shared/qr-badge/qr-badge.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SendMsgComponent } from './pages/send-msg/send-msg.component';
import { ReplyMsgComponent } from './pages/reply-msg/reply-msg.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { GenerateBadgeComponent } from './pages/generate-badge/generate-badge.component';
import { DocsComponent } from './pages/docs/docs.component';
import { SharedModule } from './modules/shared.module';


@NgModule({
  declarations: [
    AppComponent,
    GenerateBadgeFrameComponent,
    SendMsgFrameComponent,
    ReplyMsgFrameComponent,
    HomeComponent,
    TestFrameComponent,
    QrBadgeComponent,
    NotFoundComponent,
    SendMsgComponent,
    ReplyMsgComponent,
    NavbarComponent,
    GenerateBadgeComponent,
    DocsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    QRCodeModule,
    NgbModule,
    NgbModalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
