import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smile-x-change';

  constructor(private readonly translateService: TranslateService) {
    this.initializeLang();
  }

  initializeLang() {
    const browserLang = navigator.language.slice(0, 2);
    const availableLangs = ['en', 'fr', 'de', 'es'];
    if (availableLangs.includes(browserLang)) {
      this.translateService.setDefaultLang(browserLang);
    } else {
      this.translateService.setDefaultLang('en');
    }
  }

}
