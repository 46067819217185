import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-msg',
  templateUrl: './send-msg.component.html',
  styleUrls: ['./send-msg.component.scss']
})
export class SendMsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
