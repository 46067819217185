import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GENERATE_CODE_URL, EXCHANGE_URL, EXCHANGE_REPLY_URL, THEME_EXCHANGE } from '../../assets/common/constants';
import { IGetBadge } from '../interfaces/IGetBadge';
import { ISendMsg } from '../interfaces/ISendMsg';
import { IReplyMsg } from '../interfaces/IReplyMsg';

@Injectable({
  providedIn: 'root'
})
export class XChangeService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getBadge(getBadgeBody: IGetBadge) {
    return this.http.post(GENERATE_CODE_URL, getBadgeBody);
  }

  sendMsg(sendMsgBody: ISendMsg) {
    return this.http.post(EXCHANGE_URL, sendMsgBody);
  }

  replyMsg(replyMsgBody: IReplyMsg) {
    return this.http.post(EXCHANGE_REPLY_URL, replyMsgBody);
  }

  getThemeApikey(apikey: string) {
    return this.http.get(THEME_EXCHANGE + apikey);
  }
}
