import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { XChangeService } from '../../services/x-change.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-send-msg-frame',
  templateUrl: './send-msg-frame.component.html',
  styleUrls: ['./send-msg-frame.component.scss']
})
export class SendMsgFrameComponent implements OnInit {

  @ViewChild('loadingModal', null) loadingModal: TemplateRef<any>;
  @ViewChild('successModal', null) successModal: TemplateRef<any>;

  homeUrl = '';
  receiverId: any;
  exchangeMsgForm: FormGroup;
  defaultMessage = 'Message par défault';
  isSubmitted = false;
  isLoading = false;
  isEmailUsed = false;
  isPhoneNumberUsed = false;
  isReplyAllowed = true;

  constructor(
    private readonly xChangeService: XChangeService,
    private readonly route: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.initializeParams();
  }

  /**
   * Initialize the form group
   */
  initializeForm() {
    this.exchangeMsgForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      phoneNumber: new FormControl('', []),
      message: new FormControl(''),
    });
  }

  /**
   * Extract receiverId from path params
   */
  initializeParams() {
    // Extract receiverId from path
    // this.receiverId = this.route.snapshot.params.receiver;
    // console.log('this.receiverId', this.receiverId);

    // Extract receiverId from full href path
    const pathDevided = window.parent.location.href.split('/');
    const parentReceiver = pathDevided[pathDevided.length - 1];
    this.receiverId = parentReceiver;
  }

  /**
   * Send the message
   */
  sendMessage() {
    this.isSubmitted = true;
    if (this.exchangeMsgForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.isEmailUsed = false;
    this.isPhoneNumberUsed = false;
    this.openLoadingModal();
    const body = {
      email: this.exchangeMsgForm.getRawValue().email,
      phoneNumber: this.exchangeMsgForm.getRawValue().phoneNumber,
      message: this.exchangeMsgForm.getRawValue().message.toString(),
      qrCode: this.receiverId
    };

    if (!this.isReplyAllowed) {
      delete body.email;
      delete body.phoneNumber;
    }

    this.xChangeService.sendMsg(body).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.closeModals();
        this.openSuccessModal();

      }, error => {
        this.isLoading = false;
        this.closeModals();
      });
  }

  openLoadingModal() {
    this.modalService.open(this.loadingModal, {
      centered: true,
      windowClass: 'loadingModalClass',
      backdrop: 'static',
    });
  }

  openSuccessModal() {
    this.modalService.open(this.successModal, {
      centered: true,
      windowClass: 'customModalClass',
    });
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  checkInputKey(event) {
    const chars = ['e', 'E', '+', '-'];
    return chars.includes(event.key) ? false : true;
  }

  toggleAllowReply() {
    const emailCtrl = this.exchangeMsgForm.controls.email;
    const phoneCtrl = this.exchangeMsgForm.controls.phoneNumber;

    if (this.isReplyAllowed) {
      emailCtrl.enable();
      phoneCtrl.enable();
    } else {
      this.exchangeMsgForm.controls.email.disable();
      phoneCtrl.disable();
    }


  }

}
