import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-test-frame',
  templateUrl: './test-frame.component.html',
  styleUrls: ['./test-frame.component.scss']
})
export class TestFrameComponent implements OnInit {

  receiverId: string;
  url: SafeResourceUrl;

  constructor(
    private readonly route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // console.log('URL', this.activatedRoute.url);
    // console.log('URL', this.router);
    console.log('URL', location.href);
    console.log('Parent URL', parent.location.href);

    this.receiverId = this.route.snapshot.params.receiver;
    // console.log('this.receiverId from parent', this.receiverId);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('/send-msg/' + this.receiverId);
  }

}
