import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QRCODE_BASE_URL } from '../../../assets/common/constants';
import { XChangeService } from '../../services/x-change.service';
import { IGetBadge } from '../../interfaces/IGetBadge';
import { ActivatedRoute, Router } from '@angular/router';
import htmlToImage from 'html-to-image';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generate-badge-frame',
  templateUrl: './generate-badge-frame.component.html',
  styleUrls: ['./generate-badge-frame.component.scss']
})
export class GenerateBadgeFrameComponent implements OnInit {

  // @ViewChild('qrCode', null) qrCode: QRCodeComponent;
  @ViewChild('loadingModal', null) loadingModal: TemplateRef<any>;

  qrData: string = null;
  isSubmitted = false;
  isLoading = false;
  isEmailUsed = false;
  // isPhoneNumberUsed = false;
  isBadgeExist = false;
  codeDataForm: FormGroup;
  homeUrl = '';
  language: string;
  languageList = ['fr', 'en', 'de', 'es'];
  browserLang: string;
  primaryColor: string;
  secondaryColor: string;
  apiKey: string;
  logoEnterprise: string;

  constructor(
    private readonly xChangeService: XChangeService,
    private readonly activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.apiKey = this.activatedRoute.snapshot.paramMap.get('apiKey');
    // this.checkAuthorisation();
    if (this.apiKey) {
      this.getThemeWithKey(this.apiKey);
    } else {
      this.logoEnterprise = '../../../assets/img/full-horizental-logo.svg';
    }

    this.initializeForm();
    this.initializeLang();
  }

  /**
   * Initialize the form group
   */
  initializeForm() {
    this.codeDataForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [])
    });
  }

  /**
   * Check if the component is inside an iFrame
   */
  isInsideFrame(): boolean {
    if (window.location !== window.parent.location) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check from the base url if the curent site has the authorisation to integrate the api
   */
  checkAuthorisation(): boolean {
    const baseUrl = window.parent.location.origin;
    if (baseUrl === 'https://smileandcharge.com/') {
      return true;
    }
    return false;
  }

  /**
   * Initialize theme according to user's custom parameters
   */
  initializeTheme() {
    // TODO
    document.documentElement.style.setProperty('--primary', this.primaryColor);
    document.documentElement.style.setProperty('--secondary', this.secondaryColor);
  }

  /**
   * Get the x-change user code from api and generate badge
   */
  generate() {
    this.isSubmitted = true;
    if (this.codeDataForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.isEmailUsed = false;

    const body: IGetBadge = {
      email: this.codeDataForm.getRawValue().email as string,
      phoneNumber: this.codeDataForm.getRawValue().phoneNumber.toString() as string,
    };

    this.xChangeService.getBadge(body).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.isBadgeExist = true;
        this.qrData = QRCODE_BASE_URL + 'send/' + res.qrCode;
      }, error => {
        this.isLoading = false;
        // this.closeModal();
        if (error.status === 409 && error.error.cause === 'email') {
          this.isEmailUsed = true;
        }
      });
  }

  /**
   * Get theme colors based on apiKey given
   */
  getThemeWithKey(apiKey: string) {
    this.xChangeService.getThemeApikey(apiKey).subscribe(
      (res: any) => {
        this.primaryColor = res.xchange.primaryColor ? res.xchange.primaryColor : '#48a9a6';
        this.secondaryColor = res.xchange.secondaryColor ? res.xchange.secondaryColor : '#2dce89';
        this.logoEnterprise = res.xchange.logo;
        this.initializeTheme();
      }, (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Get query if exists from url and set prefer/browser language
   */
  initializeLang() {
    this.browserLang = navigator.language.slice(0, 2);
    this.activatedRoute.queryParams.subscribe(params => {
      this.language = params['lang'];
    });

    if (this.language) {
      if (this.languageList.indexOf(this.language) !== -1) {
        try {
          switch (this.language) {
            case 'fr':
              this.translate.use(this.language);
              break;
            case 'en':
              this.translate.use(this.language);
              break;
            case 'de':
              this.translate.use(this.language);
              break;
            case 'es':
              this.translate.use(this.language);
              break;
            default: this.translate.use(this.language);
          }
        } catch (e) { }
      } else {
        this.translate.use('en');
      }
    } else if (this.languageList.indexOf(this.browserLang) !== -1) {
      this.translate.use(this.browserLang);
    } else {
      this.translate.use('en');
    }
  }

  /**
   * Download the badge as an image file
   */
  saveBadge() {
    const badge = document.getElementById('badge');
    htmlToImage.toCanvas(badge)
      .then(data => {
        let fileName: string;
        if (this.codeDataForm.get('email').value) {
          fileName = this.codeDataForm.get('email').value.split('@')[0];

        }
        const dataUrl = data.toDataURL();
        const link = document.createElement('a');
        link.download = fileName ? fileName + '.png' : 'smile-badge.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(error => { });
  }

  checkInputKey(event) {
    const chars = ['e', 'E', '+', '-'];
    return chars.includes(event.key) ? false : true;
  }

}
